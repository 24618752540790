// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const host = 'http://localhost:3000';

export const environment = {
  production: false,
  api: {
    // ict: "https://172.17.35.249:443/api/ict/v1"
    // ict: "https://172.16.37.33:443/api/ict/v1"
    // ict: "https://10.24.50.15:443/api/ict/v1"
    // ict: "https://192.168.68.107:443/api/ict/v1"
    // ict: "https://10.24.10.159:443/api/ict/v1"
    // ict: "https://192.168.43.248:443/api/ict/v1"
    // ict: "https://10.23.50.144:443/api/ict/v1",
    // auth: "https://10.23.50.144:443/api/auth/v1"
    ict: `${host}/api/ict/v1`,
    auth: `${host}/api/auth/v1`,
    his: `${host}/api/his/v1`,
    web: `${host}/api/web/v1`,
    iot: `${host}/api/iot/v1`,
    admin: `${host}/api/admin/v1`,
  },
  configs: {
    his: {
      facility: {
        default: {
          id: '44600182-8c44-4541-90bb-1dd2bbd675a5'
        }
      }
    }
  },
  encryptionKey: "n2r4u7x!A%D*G-KaPdSgVkYp3s6v8y/B",
  secretKey: "aecc379a2d3fa6ecdef6a17d8ff599624bc26376",
  userKey: "hsL~F6#'~pO3GF_Tq5]Q"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
